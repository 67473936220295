import { render, staticRenderFns } from "./MainComponent.vue?vue&type=template&id=138447b9&scoped=true&"
import script from "./MainComponent.vue?vue&type=script&lang=js&"
export * from "./MainComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!C:\\Users\\indig\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138447b9",
  null
  
)

export default component.exports