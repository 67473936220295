import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Main from "./components/MainComponent";
// import i18n from "@/i18n"
Vue.use(VueRouter);
const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return
    }
    next('/')
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return
    }
    next('/login')
};

const routes = [
    {
        path: '/', name: 'main', component: Main,
        beforeEnter: ifAuthenticated,
        children: [
            {
                path: '', name: 'main',
                component: () => import('./views/admin/apartments/AreaPage'),
            },
            {
                path: 'city/:id/', name: 'city',
                component: () => import('./views/admin/apartments/CityPage'), props: true
            },
            {
                path: 'apartment/:id/', name: 'apartment',
                component: () => import('./views/admin/apartments/ApartmentPage'), props: true
            },
            {
                path: 'house/:id/', name: 'house',
                component: () => import('./views/admin/apartments/HousePage'), props: true
            },
            {
                path: 'catalog', name: 'catalog',
                component: () => import('./views/admin/CatalogPage'),
            },
            {
                path: 'developers', name: 'developers',
                component: () => import('./views/admin/DeveloperPage'),
            },
            {
                path: 'charts', name: 'charts',
                component: () => import('./views/admin/ChartsPage'),
            }
        ]
    },
        //AUTH USER
    {
        path: '/login', name: 'login', component: () => import('./components/AuthUser'),
        beforeEnter: ifNotAuthenticated
    },
    // {
    //     path: "/",
    //     redirect: i18n.locale
    // },
    // {
    //     path: '/:locale',
    //     component: {
    //         beforeRouteEnter: setLocale,
    //         render(h) {
    //             return h('router-view');
    //         }
    //     },
    //     children: [
    //         {
    //             path: '', name: 'new', component: () => import('./views/app/MainPage'),
    //             props: true
    //         },
    //         {
    //             path: 'test', name: 'test', component: () => import('./views/app/TestPage'),
    //             props: true
    //         },
    //     ],
    // },

    {
        path: '/404', name: '404',
        component: () => import('./views/NotFound')
    },
    //ERROR REDIRECT
    {path: '*', redirect: '/404'}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


// function setLocale(to, from, next) {
//     let {locale} = to.params;
//     i18n.locale = locale;
//     next();
// }

router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.permission)) {
        if (store.getters.permissions.includes(to.meta.permission)) {
            next()
        } else {
            next('/404')
        }
    } else {
        next()
    }
});

export default router
