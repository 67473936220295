<template>
    <div id="main_component">
        <div class="wrapper ">
            <div class="sidebar" data-color="white" data-active-color="danger">
                <div class="logo">
<!--                    <a href="https://www.creative-tim.com" class="simple-text logo-mini">-->
                        <!-- <div class="logo-image-small">
                          <img src="./assets/img/logo-small.png">
                        </div> -->
                        <!-- <p>CT</p> -->
<!--                    </a>-->
                    <router-link class="simple-text logo-normal" :to="{name: 'main'}" style="text-align: center;">Halal Development</router-link>
<!--                    <a href="/" class="simple-text logo-normal">-->
<!--                        ИН-->
<!--                        &lt;!&ndash; <div class="logo-image-big">-->
<!--                          <img src="../assets/img/logo-big.png">-->
<!--                        </div> &ndash;&gt;-->
<!--                    </a>-->
                </div>
                <div class="sidebar-wrapper">
                    <ul class="nav">
                        <li :class="[currentRouteName === 'main' || currentRouteName === 'city' || currentRouteName === 'apartment' || currentRouteName === 'house' ? 'active' : '']">
                            <router-link :to="{name: 'main'}">
                                <i class="nc-icon nc-bank"></i>
                                <p>Объекты</p>
                            </router-link>
                        </li>
                        <li :class="[currentRouteName === 'catalog' ? 'active' : '']">
                            <router-link :to="{name: 'catalog'}">
                                <i class="nc-icon nc-single-copy-04"></i>
                                <p>Справочник</p>
                            </router-link>
                        </li>
                        <li :class="[currentRouteName === 'developers' ? 'active' : '']">
                            <router-link :to="{name: 'developers'}">
                                <i class="nc-icon nc-settings"></i>
                                <p>Застройщики</p>
                            </router-link>
                        </li>
                        <li :class="[currentRouteName === 'charts' ? 'active' : '']">
                            <router-link :to="{name: 'charts'}">
                                <i class="nc-icon nc-chart-pie-36"></i>
                                <p>Графки</p>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="main-panel">
                <!-- Navbar -->
                <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
                    <div class="container-fluid">
                        <div class="navbar-wrapper">
                            <a class="navbar-brand" href="javascript:;" v-if="currentRouteName === 'main'">
                                <span>
                                    Города
                                </span>
                            </a>
                            <a class="navbar-brand" href="javascript:;" v-if="currentRouteName === 'catalog'">
                                <span>
                                    Справочник
                                </span>
                            </a>
                            <a class="navbar-brand" href="javascript:;" v-if="currentRouteName === 'developers'">
                                <span >
                                    Застройщики
                                </span>
                            </a>
                            <a class="navbar-brand" href="javascript:;" v-if="currentRouteName === 'charts'">
                                <span >
                                    Графики
                                </span>
                            </a>
                            <a class="navbar-brand" href="javascript:;" v-if="currentRouteName === 'city'">
                                <span>
                                    Город
                                </span>
                            </a>
                        </div>
                        <div class="collapse navbar-collapse justify-content-end" id="navigation">
                            <ul class="navbar-nav">
                                <li class="nav-item btn-rotate dropdown">
                                    <a class="nav-link dropdown-toggle" href="http://example.com"
                                       id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <i class="nc-icon nc-circle-10"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right"
                                         aria-labelledby="navbarDropdownMenuLink">
                                        <button class="dropdown-item" @click="logout">Выход</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <!-- End Navbar -->
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainCompanent",
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
        },
        methods: {
            logout() {
                this.$store.dispatch('logoutUser').then(() => {
                    this.$router.push('/login');
                })
            },
        }
    }
</script>

<style scoped>

</style>