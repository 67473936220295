import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {http} from './http-common'
import jQuery from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'leaflet/dist/leaflet.css';
import './assets/css/paper-dashboard.css';
import Vuelidate from 'vuelidate'
import Notify from 'vue2-notify'
import i18n from './i18n'

i18n.currency = 'aed';

window.$ = window.jQuery = jQuery;
Vue.config.productionTip = false;
Vue.use(Notify, {position: 'bottom-right', closeButtonClass: 'close', enter: 'fadeIn', leave: 'fadeOut'});
Vue.use(Vuelidate);


const token = localStorage.getItem('auth_token');
if (token) {
    http.defaults.headers.common['Authorization'] = localStorage.getItem('token_type') + ' ' + token
}
// разлогиниваем если поймали error 401 не авторизован
http.interceptors.response.use(undefined, function (error) {
    if (error.response.status === 401) {
        store.dispatch('clearStore');
        router.push('/signin')
    }
    return Promise.reject(error)
});
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
