<template>
    <div id="app">
       <router-view/>
    </div>
</template>

<script>
// import $ from 'jquery'

export default {

  name: 'App',
    created() {
        // $('.notify').css('z-index', '2000');
    }
}
</script>
<style>
    /*@import "../node_modules/font-awesome/css/font-awesome.min.css";*/
</style>

