import {http} from '../http-common'
export default {
    state: {
        token: localStorage.getItem('auth_token') || '',
        is_staff: false,
        username: null,
        userId: null
    },
    mutations: {
        setUser(state, payload){
            state.token = payload
        },
        setIsStaff(state, payload){
            state.is_staff = payload
        },
        setUsername(state, payload){
            state.username = payload
        },
        setUserId(state, payload){
            state.userId = payload
        },
    },
    actions: {
        signIn({commit, getters, dispatch}, {email, password}){
            return new Promise((resolve, reject) => {
                http.post('en/auth_token/token/login', {username: email, password: password})
                    .then(user => {
                        localStorage.setItem('auth_token', user.data.auth_token);
                        localStorage.setItem('token_type', 'Token');
                        http.defaults.headers.common['Authorization'] = 'Token ' + user.data.auth_token
                        commit('setUser', user.data.auth_token)
                        commit('setUsername', email)
                        dispatch('userPermissions').then(() => {
                            if(getters.isStaff)
                                resolve(user);
                            else {
                                dispatch('clearStore')
                                reject()
                            }
                        })
                    })
                    .catch(error => {
                        localStorage.removeItem('auth_token')
                        reject(error);
                    })
            });
        },
        userPermissions({commit, getters}) {
            if (getters.isAuthenticated) {
                 return new Promise((resolve, reject) => {
                     http.get('en/user_permissions/')
                         .then(response => {
                             commit('setIsStaff', response.data.is_staff)
                             commit('setUsername', response.data.username)
                             commit('setUserId', response.data.id)
                             resolve()
                         })
                         .catch(() => {
                             commit('setIsStaff', false)
                             commit('setUsername', null)
                             commit('setUserId', null)
                             reject()
                         });
                 });
            }
        },
        logoutUser({dispatch}){
            let tokenType = localStorage.getItem('token_type')
            if(tokenType === 'Token') {
                http.post('en/auth_token/token/logout')
            }
            dispatch('clearStore')
        },
        clearStore({commit}){
            commit('setUser', '');
            commit('setIsStaff', false)
            commit('setUsername', null)
            commit('setUserId', null)
            localStorage.removeItem('auth_token');
            delete http.defaults.headers.common["Authorization"];
        },
    },
    getters: {
        isAuthenticated: state => !!state.token,
        isStaff: state => state.is_staff,
        username: state => state.username,
        userId: state => state.userId,
    }
}